import { format, subDays } from 'date-fns';
import { moneyFormat } from 'helpers/numberFormat';
import { parsePtBRDate } from 'helpers/parsePtBRDate';
import { useApp } from 'hooks/app';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'store/selector';
import { ProductSubGroup } from 'types/product';
import { Reserve } from 'types/reserve';

export interface FilterReserveProps {
  initial_date: Date;
  final_date: Date;
  branch_id: number;
  product: string;
  buyer: string;
}

export function useFetchReserve() {
  const { h2iApi } = useApp();
  const branches = useSelector(state => state.branches);
  const user = useSelector(state => state.user);
  const [loading, setLoading] = useState(false);
  const [loadingSubgroups, setLoadingSubgroups] = useState(false);
  const [subgroups, setSubgroups] = useState<ProductSubGroup[]>([]);
  const [reserves, setReserves] = useState<Reserve[]>([]);
  const [filter, setFilter] = useState<FilterReserveProps>({
    branch_id:
      user && user.branchList?.length !== 0
        ? parseInt(user.branchList.length === branches.length ? '0' : user.branchList[0])
        : 999,
    final_date: new Date(),
    initial_date: subDays(new Date(), 7),
    product: '',
    buyer: 'all',
  });

  useEffect(() => {
    if (!h2iApi) return;

    setLoadingSubgroups(true);
    h2iApi
      .get(`/api/subgrupo`)
      .then(_response => {
        const response = _response.data.RESULT[0];

        setSubgroups(response);
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        setLoadingSubgroups(false);
      });
  }, [h2iApi]);

  function handleChangeFilter(index: keyof FilterReserveProps, value: any) {
    setFilter(item => ({
      ...item,
      [index]: value,
    }));
  }

  const onSearch = useCallback(
    (filter: FilterReserveProps) => {
      if (!h2iApi) return;
      if (!user?.branchList) return;

      setLoading(true);

      const formattedInitialDate = format(filter.initial_date, "dd'.'MM'.'yyyy");
      const formattedFinalDate = format(filter.final_date, "dd'.'MM'.'yyyy");
      const productId = parseInt(filter.product);

      h2iApi
        .get('/api/getreservaproduto', {
          params: {
            data_ini: formattedInitialDate,
            data_fim: formattedFinalDate,
            id_comprador: filter.buyer === 'all' ? '' : filter.buyer,
            produto: isNaN(productId)
              ? filter.product
              : filter.product.length === productId.toString().length
              ? ''
              : filter.product,
            id_produto: isNaN(productId) ? '' : filter.product.length === productId.toString().length ? productId : '',
            id_filial: filter.branch_id || '',
          },
        })
        .then(response => {
          if (response.data.MESSAGE) {
            setReserves([]);
            return;
          }
          const _reserves: Reserve[] = response.data.itens.map((item: Reserve) => {
            item.formattedUnitValue = moneyFormat(item.valor_unitario);
            item.formattedTotalValue = moneyFormat(item.valor_total);
            item.formattedDate = parsePtBRDate(item.data).toISOString();
            return item;
          });

          setReserves(_reserves);
        })
        .catch(err => console.error(err))
        .finally(() => setLoading(false));
    },
    [h2iApi, user],
  );

  console.log(reserves);
  return {
    loading,
    reserves,
    filter,
    loadingSubgroups,
    subgroups,
    handleChangeFilter,
    onSearch,
  };
}

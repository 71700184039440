import { Grid, TextField, MenuItem, Checkbox, FormControlLabel, Button } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import CnpjInput from 'components/masked-input/CnpjInput';
import CpfInput from 'components/masked-input/CpfInput';
import { useCustomer } from '../../hooks/useCustomer';
import { CustomerInfosValidation } from '../validation/CustomerInfosValidation';
import { usePermissionRules } from 'hooks/permissionRules';
import { useApp } from 'hooks/app';

interface CustomerInfosProps {
  validation: CustomerInfosValidation;
}

const CustomerInfos: React.FC<CustomerInfosProps> = ({ validation }) => {
  const { h2iApi } = useApp();
  const [letter, setLetter] = useState(false);
  const { checkPermission } = usePermissionRules();
  const { customer, handleChange, registerType, segments } = useCustomer();

  const inputs = {
    fantasia: useRef<HTMLInputElement>(null),
    codigo_atividade: useRef<HTMLInputElement>(null),
    nome: useRef<HTMLInputElement>(null),
    cnpj_cpf: useRef<HTMLInputElement>(null),
    tipo_pessoa: useRef<HTMLInputElement>(null),
    tipo: useRef<HTMLInputElement>(null),
  };

  useEffect(() => {
    if (!h2iApi) return;

    h2iApi.get('/resellers/10378');
  }, [h2iApi]);

  return (
    <Grid container spacing={0}>
      {letter && customer && <></>}
      <Grid container direction={'row'} spacing={1}>
        {registerType === 'edit' && (
          <Grid item xs={3} md={2}>
            <TextField
              disabled
              fullWidth
              margin="normal"
              label="Código"
              placeholder="Código"
              defaultValue={customer?.codigo || ''}
              InputProps={{
                readOnly: true,
                style: { textTransform: 'uppercase' },
              }}
            />
          </Grid>
        )}
        <Grid item xs={registerType === 'edit' ? 4 : 6} md={2}>
          <TextField
            select
            fullWidth
            margin="normal"
            value={customer?.tipo}
            error={!!validation.tipo}
            helperText={validation.tipo}
            inputRef={inputs.tipo}
            onChange={e => handleChange('tipo', e.target.value)}
            label="Tipo"
          >
            <MenuItem value={'FFF'}>Fornecedor</MenuItem>
            <MenuItem value={'CCC'}>Cliente</MenuItem>
            <MenuItem value={'VVV'}>Vendedor</MenuItem>
            <MenuItem value={'VVR'}>Representante</MenuItem>
            <MenuItem value={'MCF'}>Cliente e Fornecedor</MenuItem>
            <MenuItem value={'PCF'}>Prestador de serviço</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={registerType === 'edit' ? 5 : 6} md={2}>
          <TextField
            select
            fullWidth
            margin="normal"
            value={customer?.tipo_pessoa}
            error={!!validation.tipo_pessoa}
            helperText={validation.tipo_pessoa}
            inputRef={inputs.tipo_pessoa}
            onChange={e => handleChange('tipo_pessoa', e.target.value)}
            label="Tipo Pessoa"
            disabled={registerType === 'edit'}
          >
            <MenuItem value={'J'}>Jurídica</MenuItem>
            <MenuItem value={'F'}>Física</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={6} md={3}>
          <TextField
            fullWidth
            margin="normal"
            label={customer?.tipo_pessoa === 'J' ? 'CNPJ' : 'CPF'}
            placeholder={customer?.tipo_pessoa === 'J' ? 'CNPJ' : 'CPF'}
            inputRef={inputs.cnpj_cpf}
            error={!!validation.cnpj_cpf}
            helperText={validation.cnpj_cpf}
            value={customer?.cnpj_cpf}
            onChange={e => handleChange('cnpj_cpf', e.target.value)}
            inputMode="numeric"
            InputProps={{
              readOnly: registerType === 'edit',
              inputComponent: customer?.tipo_pessoa === 'J' ? (CnpjInput as any) : (CpfInput as any),
            }}
            disabled={registerType === 'edit'}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <TextField
            fullWidth
            margin="normal"
            label="RG/IE"
            placeholder={'Se não possuir, informar "isento"'}
            value={customer?.rg_ie}
            onChange={e => handleChange('rg_ie', e.target.value)}
            inputMode="numeric"
            inputProps={{ style: { textTransform: 'lowercase' } }}
          />
        </Grid>
      </Grid>
      <Grid container direction={'row'} spacing={1}>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            margin="normal"
            label="Nome"
            placeholder="Nome"
            value={customer?.nome}
            inputRef={inputs.nome}
            error={!!validation.nome}
            helperText={validation.nome}
            onChange={e => handleChange('nome', e.target.value)}
            inputProps={{ style: { textTransform: 'uppercase' } }}
            autoCapitalize="words"
            type="text"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            margin="normal"
            label="Fantasia"
            placeholder="Fantasia"
            value={customer?.fantasia}
            inputRef={inputs.fantasia}
            error={!!validation.fantasia}
            helperText={validation.fantasia}
            onChange={e => handleChange('fantasia', e.target.value)}
            inputProps={{ style: { textTransform: 'uppercase' } }}
            autoCapitalize="words"
            type="text"
          />
        </Grid>
      </Grid>
      <Grid item xs={12} md={12}>
        <TextField
          select
          fullWidth
          margin="normal"
          disabled={!checkPermission('registrations.customers.activity') && registerType === 'edit'}
          value={customer?.codigo_atividade}
          error={!!validation.codigo_atividade}
          inputRef={inputs.codigo_atividade}
          helperText={validation.codigo_atividade}
          onChange={e => handleChange('codigo_atividade', e.target.value)}
          label="Atividade"
        >
          {segments.map(segment => (
            <MenuItem value={segment.id} onClick={() => handleChange('atividade', segment.nome)} key={segment.id}>
              {segment.nome}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid container direction={'row'}>
        <Grid item xs={12} md={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={customer?.situacao === 'A'}
                onChange={e => handleChange('situacao', e.target.checked ? 'A' : 'I')}
                color="primary"
              />
            }
            label="Ativo"
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={customer?.autonomo === 'S'}
                onChange={e => handleChange('autonomo', e.target.checked ? 'S' : 'N')}
                color="primary"
              />
            }
            label="Declaração de Autônomo"
          />
        </Grid>
        <Grid item xs={6} md={4}>
          <FormControlLabel
            control={
              <Checkbox
                checked={customer?.ecommerce === 'S'}
                onChange={e => handleChange('ecommerce', e.target.checked ? 'S' : 'N')}
                color="primary"
              />
            }
            label="E-commerce"
          />
        </Grid>
        <Grid item xs={6} md={4}>
          <FormControlLabel
            control={
              <Checkbox
                checked={customer?.emite_doc_fiscal === 'S'}
                onChange={e => handleChange('emite_doc_fiscal', e.target.checked ? 'S' : 'N')}
                color="primary"
              />
            }
            label="Emite Doc. Fiscal"
          />
        </Grid>
        <Grid item xs={6} md={4}>
          <FormControlLabel
            control={
              <Checkbox
                checked={customer?.participa_promocao === 'S'}
                onChange={e => handleChange('participa_promocao', e.target.checked ? 'S' : 'N')}
                color="primary"
              />
            }
            label="Participa Promoção"
          />
        </Grid>

        <Grid item xs={6} md={4}>
          <FormControlLabel
            control={
              <Checkbox
                checked={customer?.venda_interna === 'S'}
                onChange={e => handleChange('venda_interna', e.target.checked ? 'S' : 'N')}
                color="primary"
              />
            }
            label="Venda interna"
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <FormControlLabel
            control={
              <Checkbox
                checked={customer?.coligado === 'S'}
                onChange={e => handleChange('coligado', e.target.checked ? 'S' : 'N')}
                color="primary"
              />
            }
            label="Coligado"
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <FormControlLabel
            control={
              <Checkbox
                checked={customer?.excecao_compra_online === 'S'}
                onChange={e => handleChange('excecao_compra_online', e.target.checked ? 'S' : 'N')}
                color="primary"
              />
            }
            label="Exceção Compra Online"
          />
        </Grid>
      </Grid>

      <Button variant="text" onClick={() => setLetter(true)}>
        imprimir carta de fiança
      </Button>
    </Grid>
  );
};

export default CustomerInfos;
